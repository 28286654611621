<template>
  <div>
    <div class="_BoxHeader">
      <span>新增问题记录</span>
    </div>
    <div class="PageContain">
      <div class="_Select" style="margin: 20px 0">
        <span>年份：</span>
        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年"
          @change="getTableData"
        >
        </el-date-picker>
      </div>
      <div class="_Table">
        <el-table
          :height="elementHeight"
          :data="tableData.slice(start, end)"
          style="width: 100%"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table"
        >
          <el-table-column prop="termName" label="违反条款"> </el-table-column>
          <el-table-column prop="correctCount" label="纠正数量">
          </el-table-column>
          <el-table-column prop="improveCount" label="改进数量">
          </el-table-column>
          <el-table-column prop="unqualifiedCount" label="轻微不符合数量">
          </el-table-column>
          <el-table-column
            prop="unqualifiedSeriousCount"
            label="严重不符合数量"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
          class="_Pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      tableData: [],
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      year: '',
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize  
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    getTableData() {
      get('/api/TermStatistics?Years=' + this.year).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data
        }
      })
    },
  },
}
</script>

<style>
</style>